import React, { memo } from 'react'
import { Container } from '~ux'

const IndexPage = () => {
  return (
    <section className="bg-[#F9F9F8]">
      <Container className="pt-100 pb-120">
        <h1 className="text-42 leading-50 font-black text-[#373773] pb-110">
          Карьерные порталы
          <br />
          <div className="inline text-[#FF7143]">и лендинги для HR-мероприятий</div>
        </h1>

        <div className="grid grid-cols-2 gap-x-64 gap-y-120 items-center xs:grid-cols-1 xs:gap-y-64">
          <div className="max-w-[80%] xs:order-1">
            <h2 className="text-32 leading-40 text-purple font-semibold md:text-22">Быстро</h2>
            <p className="text-18 text-purple/60 leading-28 mt-24 md:text-14 md:leading-22 md:mt-16">
              <b>Мероприятия для студентов, one day offer, ярмарки вакансий</b> - поможем сделать лендинги для них в
              течение считанных часов в нашем конструкторе
            </p>
          </div>
          <img className="xs:order-2" src="/images/landing-image-1.png" alt="Быстро" />

          <img className="xs:order-4" src="/images/landing-image-2.png" alt="Гибко" />
          <div className="max-w-[80%] xs:order-3">
            <h2 className="text-32 leading-40 text-[#FF7143] font-semibold md:text-22">Гибко</h2>
            <p className="text-18 text-purple/60 leading-28 mt-24 md:text-14 md:leading-22 md:mt-16">
              Можно разработать визуальную часть <b>любой сложности</b> и подключить к нашей{' '}
              <b>системе управления контентом</b>
            </p>
          </div>

          <div className="max-w-[80%] xs:order-5">
            <h2 className="text-32 leading-40 text-purple font-semibold md:text-22">Масштабируемо</h2>
            <p className="text-18 text-purple/60 leading-28 mt-24 md:text-14 md:leading-22 md:mt-16">
              От лендинга для одной горящей вакансии <b>до комплексного карьерного портала</b>
            </p>
          </div>
          <img className="xs:order-6" src="/images/landing-image-3.png" alt="Масштабируемо" />

          <div className="bg-white py-24 px-24 rounded-[30px] shadow-md xs:order-8">
            <img src="/images/atsLogos-new.png" alt="Актуально" />
          </div>
          <div className="max-w-[80%] xs:order-7">
            <h2 className="text-32 leading-40 text-[#FF7143] font-semibold md:text-22">Актуально</h2>
            <p className="text-18 text-purple/60 leading-28 mt-24 md:text-14 md:leading-22 md:mt-16">
              Благодаря быстрой настройке интеграций с основными ATS и HCM карьерный портал всегда показывает{' '}
              <b>актуальные вакансии</b>, а кандидаты <b>мгновенно передаются для дальнейшей обработки</b>
            </p>
          </div>

          <div className="max-w-[80%] xs:order-9">
            <h2 className="text-32 leading-40 text-purple font-semibold md:text-22">Оптимально</h2>
            <p className="text-18 text-purple/60 leading-28 mt-24 md:text-14 md:leading-22 md:mt-16">
              Лендинги уже соответсвуют всем требованиям <b>для SEO и для эффективного ведения рекламных кампаний</b>
            </p>
          </div>
          <img className="xs:order-10" src="/images/landing-image-4.png" alt="Оптимально" />

          <img className="xs:order-12" src="/images/landing-image-5.png" alt="Безопасно" />
          <div className="max-w-[80%] xs:order-11">
            <h2 className="text-32 leading-40 text-[#FF7143] font-semibold md:text-22">Безопасно</h2>
            <p className="text-18 text-purple/60 leading-28 mt-24 md:text-14 md:leading-22 md:mt-16">
              Наше облачное решение полностью <b>соответствует ФЗ-152</b>, также возможно{' '}
              <b>размещение в контуре заказчика</b>
            </p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default memo(IndexPage)
